<template>
  <div>
    <index-produit-component v-if="getProd=='getProduits'" :motCle="motCle" @updateMotCle="updateMotCle"></index-produit-component>
    <produit-detail-component  v-if="getProd=='ProduitDetail'"></produit-detail-component>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import IndexProduitComponent from './IndexProduit.vue'
import ProduitDetailComponent from './ProduitDetail.vue'
export default {
    components:{IndexProduitComponent,ProduitDetailComponent},
    created:function(){
    },
    data() {
        return {
            motCle:null
        }
    },
    mounted() {
    },
    computed: {
        ...mapGetters(["getProd"]),
    },
    methods: {
        checkTypeSearch(value){},
        updateMotCle(value){
            this.motCle=value
        }
    }
}
</script>