<template>
<div>
<div class="vue-form-wizard mb-3 md">
   <b-overlay :show="showLoading" no-wrap />
    

    <b-row class="mb-2 mx-1">
      <b-col col>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-1 mt-1">
            <h1 class="text-primary font-weight-bolder text-uppercase ml-1">Produits</h1>
          </div>
        </div>
      </b-col>
      <b-col class="col-auto" v-show="false">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-toggle.sidebar-1 @click="isActive = true"
          variant="primary" size="md" class="ml-1 mt-1">
          <feather-icon icon="PlusIcon" />
          <span class="align-middle"> Créer un devis</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row >
          <b-col>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group 
              id="box-custom-modale-sm"
              :options="produits"
              :aria-describedby="ariaDescribedby"
              v-model="produitType"
              @change="changeProduit"
              name="flavour-1"
              button-variant="outline-primary box-custom-modale-child"
              buttons>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
      </b-row>
</div>
<b-sidebar id="sidebar-1" bg-variant="white" shadow backdrop no-header right ref="sideBarProduitExterne">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-1 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Nouveau Produit</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="produitComposeRules">
          <b-form class="mt-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <b-form-group label="Produit Nom" label-for="produit_nom" class="px-1">
               <validation-provider #default="{ errors }" name="Produit Nom" rules="required">
                 <b-form-input v-model="produit.produit_nom" :state="errors.length > 0 ? false:null" placeholder="Produit Nom" />
                 <small class="text-danger">{{ errors[0] }}</small>
               </validation-provider>
            </b-form-group>
            <b-form-group label="Date d'effet" label-for="date_effet" class="px-1">
              <validation-provider #default="{ errors }" name="Date d'effet" rules="required">
                <flat-pickr class="form-control" v-model="produit.date_effet" :class="errors.length > 0 ? 'is-invalid' : ''" id="date_effet" :config="configDate" placeholder="Date d'effet" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Date Debut Comm" label-for="Dt_Debut_Comm" class="px-1">
              <validation-provider #default="{ errors }" name="Date Debut Comm" rules="required">
                <flat-pickr class="form-control" v-model="produit.date_Debut_Comm" :class="errors.length > 0 ? 'is-invalid' : ''" id="Dt_Debut_Comm" :config="configDate" placeholder="Date Debut Comm" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Date Fin Comm" label-for="Dt_Fin_Comm" class="px-1">
              <validation-provider #default="{ errors }" name="Date Fin Comm" rules="required">
                <flat-pickr class="form-control" v-model="produit.Dt_Fin_Comm" :class="errors.length > 0 ? 'is-invalid' : ''" id="Dt_Fin_Comm" :config="configDate" placeholder="Date Fin Comm" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group class="px-1">
                          <div class="d-flex">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
                @click="submitForm_create_produit"> Enregistrer </b-button>
            </div>
            </b-form-group>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BPagination, BFormGroup, VBToggle, BForm, BFormInput, BFormSelect, BRow, BCol,BButton,BCard,BDropdown, BDropdownItem,BFormCheckboxGroup, BFormInvalidFeedback, BOverlay, BSidebar
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import {required} from '@validations';

export default {
  components: {
    Ripple,
    BCardCode,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BOverlay,
    BFormGroup,
    BAvatar,
    BPagination,
    BForm,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    flatPickr,
    BButton,
    BFormInvalidFeedback,
    BCard,
    BDropdown, 
    BDropdownItem,
    BFormCheckboxGroup,
    ToastificationContent,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      produitValue:'',
      produitType:null,
      produits:[],
      showLoading:false,
      configDate: {
        dateFormat: 'd/m/Y'
      },
      produit:{
        produit_nom: '',
        produit_ws_nom: '',
        assureur_id : null,
        Dt_Fin_Comm: null,
        date_Debut_Comm: null,
        date_effet: null,
        risque_id: 53,
      },
      required
    }
  },
  created(){
    this.getAllProduits()
  },
  mounted() {
  },
  methods: {
    getAllProduits() {
      this.showLoading = true;
      this.$http.get(`produit/getAllProduits`)
      .then((res) => {
        this.produits = []
        Array.from(res.data).forEach(produit => 
          this.produits.push({text: produit.produit_nom, value: produit.id})
        );
        this.showLoading = false;
      })
      .catch((e)=>{
         console.log(e);
         this.showLoading = false;
      })
    },
    changeProduit(){
            this.showLoading=true
            let data={
                type:'produit',
                data:null
            }
            this.$http
                .get(`produit/getDetailsProduit/${this.produitType}`)
                .then((r) => {
                    data.data = r.data
                    this.$store.commit("setProduit", {
                        produit: data,
                    });
                    this.$store.commit("setProd", {
                        prod: 'ProduitDetail',
                    });
                    this.showLoading=false
                }).catch((err)=>{
                    console.log(err)
                    this.showLoading=false
                });
        },
        hideSideBare() {
          this.$refs.sideBarProduitExterne.hide()
        },
        submitForm_create_produit() {
            this.$refs.produitComposeRules.validate().then(success => {
              if (success) {
                const userData = JSON.parse(localStorage.getItem('userData'))
                this.produit.assureur_id = userData.assureur_user.id
                this.produit.produit_ws_nom = this.produit.produit_nom
                this.$http.post('/produit/addProduit/', this.produit).then(response => {
                  try {
                    if(response) {
                      this.messageToast('Produit a été ajouté succès ', 'Produit', 'success', 'BellIcon')
                      this.hideSideBare();
                      this.getAllProduits();
                    }
                    
                  } catch (e) {
                    this.messageToast(e.response.data.errors[0], 'Erreur', 'error')
                    this.errors = e.response.data.errors
                  }
                })
              }
            })
        }
  },
  watch: {

  },
  computed: {
    ...mapGetters(["getProd"]),
  }

}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';

#box-custom-modale.btn-group,#box-custom-modale-sm {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
  width: 100%;
}

#box-custom-modale > .box-custom-modale-child {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
    background-color: #fff;
}

#box-custom-modale-sm > .box-custom-modale-child {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
    background-color: #fff;
}
#box-custom-modale > .box-custom-modale-child.active {
  background-color: #4d25bc;
  color: #fff;
}
#box-custom-modale > .box-custom-modale-child.active {
  background-color: #4d25bc;
  color: #fff;
}
#box-custom-modale-sm .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}
// #box-custom-modale-sm .btn-outline-primary:hover:not(.disabled):not(:disabled) {
//   background-color: #4d25bc;
//   color: #fff;
//   transition: 0.4s;
// }
</style>