<template>
    <div>
        <b-overlay :show="showLoading" no-wrap />
        <div class="row mb-2">
            <div class="col">
                    <div class="d-flex">
                        <div class="position-relative mr-2">
                            <div class="position-relative mb-1">
                                <img :src="logo ? logo : produit.assureur.assureur_image"  alt="logo" id="logo" height="120" />
                             
                                                            

                            <b-button  v-if="edit" variant="primary"
                                size="sm"
                                @click="$refs.refInputEl.$el.click()"
                                class="btn-icon rounded-circle edit-img">
                                <feather-icon icon="EditIcon" />
                            </b-button>
                            </div>
                            <h1 class="text-primary font-weight-bolder mr-2">{{ produit.produit_nom }}</h1>

                            <b-form-checkbox v-if="!isCatalogue && currentUser.assureur_user.isPrincipal" v-model="edit" name="check-button" switch inline></b-form-checkbox>
                            <!-- <img v-else src="@/assets/images/logo/logo.svg" alt="logo" height="60" /> -->
                        </div>

                        <!-- <a style="margin-left:300px; width: 10px; height: 10px; cursor:default" @click="update"></a> -->

                    </div>
                
                <b-col class="md-12" v-if="edit">
                    <!-- media -->
                    <b-media no-body>
                        <b-media-body class="mt-75 ml-75">
                            <!-- upload button -->
                            <!-- <b-button variant="primary" size="sm"
                                class="mb-75 mr-75" @click="$refs.refInputEl.$el.click()" >
                                Upload
                            </b-button> -->
                            <b-form-file ref="refInputEl" accept=".jpg, .png, .gif" @input="readURL" :hidden="true"
                                plain />
                            <!--/ upload button -->
                        </b-media-body>
                    </b-media>
                    <!--/ media -->
                </b-col>
            </div>
            <div class="col-auto">
                <b-button block variant="outline-primary" @click="backStep">
                    <feather-icon icon="ArrowLeftIcon" class="mr-50" />
                    <span>REVENIR À LA LISTE</span>
                </b-button>
            </div>
        </div>
        <section>
            <b-row>
                <b-col lg="9">
                    <b-card>
                        <b-card-header class="flex-column align-items-start">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="FeatherIcon" size="20" />DESCRIPTION</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                        <b-card-body>
                            <div v-if="!edit" v-html="produit.description" class="text-justify"></div>
                            <div v-else>
                                <b-row>
                                    <b-col class="col-12">
                                        <quill-editor v-model="produit.description" />
                                    </b-col>
                                    <b-col class="col text-right">
                                        <b-button variant="primary" class="mt-1" @click="updateDescription()">
                                            <feather-icon icon="CheckIcon" />
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card-body>
                    </b-card>
                    <b-card v-show="false">
                        <b-card-header class="flex-column align-items-start">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="FilmIcon" size="20" />DIDACTICIEL</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                    </b-card>
                    <b-card>
                        <b-card-header class="flex-column align-items-start pt-0">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="FileIcon" size="20" />DOCUMENT À
                                    TÉLÉCHARGER</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                        <b-card-body>
                            <b-row class="text-center">
                                <b-col md="12" class="mb-1 d-flex" v-for="doc in documents" :key="doc.id">
                                    <div class="mb-1 mr-2 float-left">
                                        <!-- @click="downloadDocument(doc.document.base64, doc.document.nom)" -->
                                        <b-img size="40" :src="require('@/assets/images/icons/doc.png')" />
                                    </div>
                                    <b-card-text class="mb-0 text-left">
                                        <strong>Titre:</strong> {{ doc.document.nom_custom }} <br>
                                         <strong>Référence:</strong> {{ doc.reference }} <br>
                                        <!-- <strong>Date de creation:</strong> {{ doc.document.created_at | formatDate }} -->
                                    </b-card-text>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="1" class="mb-1" v-if="edit">
                                    <b-button id="toggle-btn" v-b-modal.modal-prevent-closing variant="outline-primary">
                                        <feather-icon class="box-icon-text" icon="FilePlusIcon" size="35" />
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col lg="3">
                    <b-card>
                        <b-card-header class="flex-column align-items-start">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="TargetIcon" size="20" />CIBLE</h4>
                            </b-card-title>
                        </b-card-header>
                        <b-row v-if="edit">
                            <b-col class="col-12">
                                <b-form ref="form" :style="{height: trHeight}" class="repeater-form"
                                    @submit.prevent="repeateAgain">
                                    <!-- Row Loop -->
                                    <b-row v-for="(cible, index) in cibles" :id="index" :key="index" ref="row">
                                        <!-- Item Name -->
                                        <b-col class="col-md-8 col-8">
                                            <b-form-group label="Cible" label-for="cible-name">
                                                <b-form-input id="cible-name" type="text" placeholder=""
                                                    v-model="cible.label" />
                                            </b-form-group>
                                        </b-col>
                                        <!-- Remove Button -->
                                        <b-col class="col-md-3 col-md-3 mb-50">
                                            <b-button variant="outline-danger" class="mt-0 mt-md-2"
                                                @click="removeCible(index)">
                                                <feather-icon icon="XIcon" class="mr-25" />
                                            </b-button>
                                        </b-col>
                                    </b-row>

                                </b-form>
                            </b-col>
                            <b-col class="col-12">
                                <b-row class="justigy-content-between">
                                    <b-col class="col-2">
                                        <b-button variant="primary" @click="repeateAgain">
                                            <feather-icon icon="PlusIcon" />
                                        </b-button>

                                    </b-col>
                                    <b-col class="col-md-2">
                                        <b-button variant="primary" @click="updateCibles">
                                            <feather-icon icon="CheckIcon" />
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-card-body v-else>
                            <b-list-group>
                                <b-list-group-item v-for="data in cibles" :key="data.id">
                                    {{ data.label }}
                                </b-list-group-item>
                            </b-list-group>
                        </b-card-body>
                    </b-card>
                    <b-card>
                        <b-card-header class="flex-column align-items-start pt-0">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="AwardIcon" size="20" />RÉCOMPENSES</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                        <b-card-body>
                            <b-row class="text-center">
                                <b-col md="6" cols="12" class="mb-1" v-for="(recompense, index) in recompenses"
                                    :id="index" :key="index">
                                    <div class="mb-1 float-left">
                                        <b-img size="20" :src="recompense.base64" class="img-fluid" />
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row v-if="edit">
                                <b-col class="col-md-8">
                                    <validation-observer ref="recompenseSimpleRules">
                                    <validation-provider #default="{ errors }" name="Image" rules="required|size:100000" >
                                    <b-form-file v-model="img" :state="Boolean(img)" :class="errors.length > 0 ? 'is-invalid' : ''" placeholder="Choose a file..."
                                        accept=".jpg, .png, .gif" @change="onFileChange" ref="file_upload"/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </validation-observer>
                                </b-col>
                                <b-col class="col-md-4">
                                    <b-button variant="primary" @click="uploadRecompense">
                                        <feather-icon icon="CheckIcon" />
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </section>
        <!-- select 2 demo -->
        <b-modal id="modal-prevent-closing" title="Ajouter Document" ref="modal-document" ok-title="Enregistrer"
            cancel-title="Exit" cancel-variant="outline-secondary" @show="resetModal" @hidden="resetModal"
            @ok="handleOk">
            <validation-observer ref="simpleRules">
                <form ref="documentForm" @submit.stop.prevent="handleSubmit">
                    <b-form-group label="Titre" label-for="titre">
                        <validation-provider #default="{ errors }" name="Titre" rules="required" >
                            <b-form-input v-model="document.nom_document" :class="errors.length > 0 ? 'is-invalid' : ''" placeholder="Titre" id="titre" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label="Référence" label-for="reference">
                        <validation-provider #default="{ errors }" name="Référence" rules="required">
                            <b-form-input v-model="document.reference" :class="errors.length > 0 ? 'is-invalid' : ''" placeholder="Référence" id="reference" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label="Document Nature" label-for="vue-select" v-show="false">
                        <b-form-select v-model="document.famille_doc" :options="options_nature" text-field="libelle"
                            value-field="id" @change="onNaturesChange($event)">
                            <template #first>
                                <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group label="Document Type" label-for="vue-select">
                        <validation-provider #default="{ errors }" name="Document Type" rules="required" >
                        <b-form-select v-model="document.type_doc" :class="errors.length > 0 ? 'is-invalid' : ''" :options="options_type" text-field="type"
                            value-field="id" label-field="Select Document Type">
                            <template #first>
                                <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                            </template>
                        </b-form-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label="Garanties" label-for="vue-select">
                        
                        <b-form-select v-model="garantie" :options="garanties_option" text-field="label"
                            value-field="id" label-field="Select Garantie">
                            <template #first>
                                <b-form-select-option selected :value="null">-- Choisissez --</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group label="Note" label-for="textarea-note">
                        <b-form-textarea id="textarea-note" placeholder="Note" rows="3" v-model="document.note" />
                    </b-form-group>
                    <b-form-group label="Document" label-for="Document">
                        <validation-provider #default="{ errors }" name="Document" rules="required|size:10000" >
                        <b-form-file v-model="file" :state="Boolean(file)" :class="errors.length > 0 ? 'is-invalid' : ''" placeholder="Choisir un fichier..."
                            accept=".pdf, .doc, .docx" @change="onFileChange" ref="file_upload" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </form>
            </validation-observer>
        </b-modal>
    </div>

</template>
<script>
import {
    mapGetters
} from "vuex";
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
    heightTransition
} from '@core/mixins/ui/transition'
import {
    quillEditor
} from 'vue-quill-editor'
import BCardCode from '@core/components/b-card-code'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import moment from 'moment'
import {
    BImg,
    BOverlay,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    BCard,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
    BFormInput,
    BModal,
    VBModal,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BFormTextarea
} from 'bootstrap-vue'
import {
    required
} from '@validations'
export default {
    components: {
        BForm,
        BFormGroup,
        BFormInput,
        BImg,
        BOverlay,
        BRow,
        BCol,
        BModal,
        BFormFile,
        BCardHeader,
        BCardTitle,
        vSelect,
        BFormSelect,
        BFormSelectOption,
        BCardCode,
        quillEditor,
        ValidationProvider,
        ValidationObserver,
        // ToastificationContent,
        BFormCheckbox,
        BListGroup,
        BListGroupItem,
        BCardText,
        BLink,
        BCard,
        BCardBody,
        BTabs,
        BTab,
        BButton,
        BFormTextarea,
        BMedia,
        BMediaAside,
        BMediaBody,
    },
    directives: {
        'b-modal': VBModal,
    },
    mixins: [heightTransition],
    created: function () {
        this.produit = this.$store.state.produitModule.produit.data;
        this.cibles = this.produit.produit_cibles

        if(this.base64regex.test(this.produit.logo_name))
            this.logo = this.produit.logo_name
    },
    data() {
        return {
            currentUser: JSON.parse(localStorage.getItem("userData")),
            selected: null,
            natureSelected: null,
            typeSelected: null,
            previewImage: null,
            titre: '',
            titreState: null,
            logo: null,
            indexAssureur: 0,
            options_nature: [],
            options_type: [{
                    id: 12,
                    type: "IPID"
                },
                {
                    id: 13,
                    type: "CG"
                },
                {
                    id: 14,
                    type: "TG"
                },
                {
                    id: 58,
                    type: "CA"
                },
                {
                    id: 59,
                    type: "PL"
                },
                {
                    id:108,
                    type:'PLC'
                }
            ],
            file: null,
            img: null,
            produit: {},
            cibles: [],
            base64regex: /^data:image\/([a-zA-Z]*);base64,([^\"]*)?$/,
            edit: false,
            showLoading: false,
            document: {
                date_creation: null,
                reference:null,
                famille_doc: null,
                type_doc: null,
                note: null,
                nom_document: null,
                file: null,
            },
            documents: [],
            recompenses: [],
            required,
            garanties_option: [],
            garantie:null,
            // nextTodoId: 1
        }
    },
    props: {
        isCatalogue: {
            type: Boolean,
            default: false,
            required: false
        },
        fromList: {
            type: Boolean,
            default: false,
            required: false
        },
    },
    mounted() {
        this.loadDocumentsData()
        this.loadRecompenses()
        this.getNaturesWithTypes()
        this.fetchGarantie()
    },
    computed: {},
    beforeDestroy() {
        this.$store.commit("setProd", {
            prod: 'getProduits',
        });
    },
    methods: {
        update() {
            if (this.edit) {
                this.edit = false
            }
            else {
                this.edit = true
            }

        },
        fetchGarantie(){
            console.log(this.produit.produit_garanties)
            this.produit.produit_garanties.forEach((produit_garantie, index) => {
                if(index > 0)
                this.garanties_option.push({ id: produit_garantie.id,label: produit_garantie.libelle})
            })
        },
        clearLogoFile() {
            this.$refs["logo-input"].reset();
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit(bvModalEvt) {
            // Exit when the form isn't valid
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.titreState = true
                    this.submitDocument()
                    this.$nextTick(() => {
                        this.$refs['modal-document'].toggle('#toggle-btn')
                    })
                } else {
                    this.titreState = false
                    return
                }
            })
        },
        submitDocument() {
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            this.showLoading = true
            let formData = new FormData()
            formData.append('file', this.document.file)
            formData.append('produit_id', this.produit.id)
            formData.append('type_id', this.document.type_doc)
            formData.append('nom', this.document.nom_document)
            formData.append('note', this.document.note)
            formData.append('reference', this.document.reference)
            formData.append('garantie', this.garantie)


            this.$http.post(`produit/saveDocument`, formData, config).then(r => {
                if (r) {
                    this.messageToast('Document a été ajouté succès ', 'Document', 'success', 'BellIcon')
                }
                this.loadDocumentsData()
                this.showLoading = false
                this.$refs.simpleRules.reset()
            }).catch(err => {
                this.showLoading = false
            })
        },
        uploadRecompense() {
            this.$refs.recompenseSimpleRules.validate().then(success => {
                if(success) {
                    let config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    this.showLoading = true
                    let formData = new FormData()
                    formData.append('file', this.img)
                    formData.append('produit_id', this.produit.id)

                    this.$http.post(`document/saveRecompense`, formData, config).then(r => {
                        if (r) {
                            this.$refs.recompenseSimpleRules.reset();
                            this.messageToast(r.data.message, 'Récompense', 'success', 'BellIcon')
                            this.loadRecompenses()
                            this.showLoading = false
                        }
                    }).catch(err => {
                        this.showLoading = false
                    })
                }
            })
        },
        loadDocumentsData() {
            this.showLoading = true
            this.$http.get(`produit/getAssureurDocs/${this.produit.assureur_id}/${this.produit.id}`)
                .then(({
                    data
                }) => {
                    this.showLoading = false
                    this.documents = data.data.list
                })
                .catch(() => {
                    this.showLoading = false
                    console.log("Error...")
                })
        },
        downloadDocument(base64, name) {
        if (base64) {
            const a = document.createElement('a')
            a.href = base64
            a.download = name
            a.click()
            this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
        } else {
            this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
        }
        },
        loadRecompenses() {
            this.showLoading = true
            this.$http.get(`document/getProduitRecom/${this.produit.id}`)
                .then(({
                    data
                }) => {
                    this.recompenses = data.data.list
                    this.showLoading = false
                })
                .catch(() => {
                    this.showLoading = false
                    console.log("Error...")
                })
        },
        getNaturesWithTypes() {
            this.showLoading = true
            this.$http.get(`document_nature/listdocument`)
                .then(({
                    data
                }) => {
                    this.showLoading = false
                    this.options_nature = data
                })
                .catch(() => {
                    this.showLoading = false
                    console.log("Error...")
                })
        },
        resetModal() {
            this.titreState = null
            this.titre = null
            this.document = {
                date_creation: null,
                reference:null,
                famille_doc: null,
                type_doc: null,
                note: null,
                nom_document: null,
                file: null,
            }
        },
        backStep() {
            if(this.$route.params.back_to)
                this.$router.push({ name: this.$route.params.back_to})
            if(this.isCatalogue) {
                this.$store.commit("setStep", {
                    step: this.fromList ? 'searchCatalogue' : 'AssureurDetail',
                });
            }
            else {
                this.$store.commit("setProd", {
                    prod: 'getProduits',
                });
            }
        },
        updateDescription() {
            this.showLoading = true
            this.$http
                .put(`produit/updateDescription`, this.produit)
                .then((response) => {
                    if (response.data.success) {
                        this.showLoading = false
                        this.messageToast(response.data.message, 'Produit', 'success', 'BellIcon')
                    }
                }).catch((err) => {
                    this.showLoading = false
                    console.log(err)
                });
        },
        repeateAgain() {
            this.cibles.push({
                produit_id: this.produit.id,
                label: null,
            })
        },
        removeCible(index) {
            let label = this.cibles[index].label
            this.cibles.splice(index, 1)
            if (label)
                this.updateCibles()
        },
        updateCibles() {
            this.showLoading = true
            let data = {
                cibles: this.cibles.filter(cible => cible.label !== null)
            }
            this.$http
                .post(`produit/updateCiblesProduit/${this.produit.id}`, data)
                .then((response) => {
                    if (response.data.success) {
                        this.showLoading = false
                        this.messageToast(response.data.message, 'Cibles Produit', 'success', 'BellIcon')
                    }
                }).catch((err) => {
                    this.showLoading = false
                    console.log(err)
                });
        },
        onFileChange(event) {
            this.document.file = event.target.files[0]
        },
        readURL() {
            this.showLoading = true

            let input = this.$refs.refInputEl
            let file = input.files

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            let formData = new FormData()
            formData.append('logo', file[0])
            formData.append('produit_id', this.produit.id)

            this.$http.post(`produit/updateLogo`, formData, config).then(r => {
                if (r) {
                    if (file && file[0]) {
                        let reader = new FileReader
                        reader.onload = e => {
                            this.logo = e.target.result
                        }
                        reader.readAsDataURL(file[0])
                        this.$emit('input', file[0])
                    }
                    
                    this.messageToast(r.data.message, 'Produit logo', 'success', 'BellIcon')
                }
                this.loadRecompenses()
                this.showLoading = false
            }).catch(err => {
                this.showLoading = false
            })
        },
        resetFile() {
            this.$refs.file_upload.value = null;
            this.file = null
            this.img = null
        },
        onNaturesChange(event) {
            this.options_nature.filter(option => {
                if (option.id == event)
                    this.options_type = option.documentype
            });
        },
        isBase64(str) {
            if (str ==='' || str.trim() ===''){ return false; }
            try {
                return btoa(atob(str)) == str;
            } catch (err) {
                return false;
            }
        }

    }
}
</script>
<style>
.title-custom-wizard{
    color: #4d25bc !important ;
    margin-bottom: 20px;
}
.box-icon-text{
    margin-top: -4px;
    margin-right: 5px;
}
.edit-img {
    position: absolute;
    bottom: -5px;
    right: -15px;
    z-index: 6;
}
</style>